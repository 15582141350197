<template>
  <navbar v-model="showNavbar">

    <template slot="navbar-menu">
      <div id="overall-bar" style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">
        <a href="www.acurissystems.com" src="/img/icons/acuris-plain.svg">
          <img class="logo" src="/img/icons/acuris-plain.svg">
        </a>

        <div class="select-container">
          <div>
            <fg-input>
              <el-select class="select-primary" size="large" placeholder="Orchard Unknown" v-model="$root.orchard"
                @change="changeOrchard($event)">
                <el-option v-for="option in this.$root.orchard_list" class="select-danger" :value="option"
                  :label="option.name" :key="option.value">
                </el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="select-right">
            <fg-input>
              <el-select class="select-primary" size="large" placeholder="Scan Unselected" v-model="$root.scan"
                @change="changeScan($event)">
                <template v-slot:prefix>
                  <!-- <div :class="current_icon" style="margin: 12px 0 12px 5px;"></div> -->
                  <div :class="$root.scan.icon" style="margin: 12px 0 12px 5px;"></div>
                </template>
                <el-option v-for="option in this.$root.scan_list" class="select-danger" :value="option"
                  :label="option.name" :key="option.value">
                  <slot>
                    <span :class="option.icon"></span> {{ option.name }}
                  </slot>
                </el-option>
              </el-select>
            </fg-input>
          </div>
        </div>

      </div>
    </template>

  </navbar>
</template>

<script>
import { Select, Option } from 'element-ui'
import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      testStr: "hello",
      activeNotifications: false,
      showNavbar: false,
      current_icon: null,
    }
  },
  mounted() {
    var offsetHeight = document.getElementById('overall-bar').offsetHeight;
    var rem = 0.625
    var rem_pix = rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    // console.log(rem, rem_pix)
    this.$root.navbar_height = offsetHeight + 10 + rem_pix
  },
  methods: {
    findMostRecentScan(scan_list) {
      let selected_scan = {"value": null, "name": null, "icon": null, "date": null}
      if (scan_list) {
        if (scan_list.length > 0) {
          selected_scan = scan_list[0]
          for (let i = 1; i < scan_list.length; i++) { // loop through scans past 1
            if (this.moreRecent(scan_list[i], selected_scan)) {
              selected_scan = scan_list[i]
            }
          }
        }
      }
      return selected_scan
    },
    moreRecent(date_str_a, date_str_b) { // Is A more recent than B
      let date_a = Date.parse(date_str_a)
      let date_b = Date.parse(date_str_b)
      if (date_a > date_b) {
        return true
      }
      return false
    },
    changeOrchard(e) {
      console.log("changeOrchard:", e.value)
      this.emitScanChanged()
      this.$root.scan_list = e.scan_list
      this.$root.scan = this.findMostRecentScan(e.scan_list)
      this.setURL()
    },
    changeScan(e) {
      console.log("changeScan:", e.value)
      this.emitScanChanged()
      this.current_icon = e.icon
      this.setURL()
      let vl = this.$root.$refs.LandingMap.$refs.map.$map.getLayers().item(1)
      vl.getSource().changed()
    },
    emitScanChanged() {
      console.log("emitScanChanged::")
      // this.emitNavbarResize()
      document.dispatchEvent(new Event('scan_changed'));
      // this.$emit('scan_changed');
      this.$emit('my-event');
    },
    setURL() {
      if (this.$root.scan.value === null) {
        window.history.pushState(null, "", `#/admin/landing-map/${this.$root.orchard.value}`)
      } else {
        window.history.pushState(null, "", `#/admin/landing-map/${this.$root.orchard.value}/${this.$root.scan.value}`)
      }
    }
  }
}
</script>

<style>
/* .el-scrollbar__bar {
    opacity: 1!important;
} */
.logo {
  height: 41px;
  max-height: 41px;
}

.select-container {
  display: flex;
  flex-direction: row;
}

.select-right {
  padding-left: 20px;
}

.el-input input {
  font-size: 1rem;
}

.el-select-dropdown__item {
  font-size: 1rem;
}

@media (hover: none) {
  el-select:hover {
    color: red
  }

  el-option:hover {
    color: red
  }
}

@media (max-aspect-ratio: 1/1) {
  .logo {
    content: url("../../../../public/img/icons/android-chrome-192x192.png");
    margin-left: 15px;
    background: black;
    min-height: 100%;
    border-radius: 25px;
  }

  .select-right {
    padding-left: 10px;
  }

  .select-container {
    display: flex;
    flex-direction: row;
    margin: 0 5px 0 25px;
  }

  .navbar.navbar-transparent {
    background: transparent;
    box-shadow: none;
  }
}

@media (max-height: 30em) and (orientation: landscape) {
  .navbar.navbar-transparent {
    background: transparent;
    box-shadow: none;
  }

  .logo {
    content: url("../../../../public/img/icons/android-chrome-192x192.png");
    margin-left: 15px;
    background: black;
    min-height: 100%;
    border-radius: 25px;
  }
}

</style>
