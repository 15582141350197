<template>
  <div>
    <notifications transition-name="notification-list" transition-mode="out-in"></notifications>
    <router-view name="header"></router-view>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
<!--    <router-view name="footer"></router-view>-->
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import 'sweetalert2/dist/sweetalert2.css'
import 'vue-notifyjs/themes/default.css'
// import landingmap and topnavbar?

export default {} // export components landingmap and topnavbar
</script>

<style lang="scss">

</style>
